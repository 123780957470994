import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Air() {
  return (
    <>
      <Header />

      {/* Page Header Start */}
      <div className="container-fluid page-header py-5">
        <div className="container text-center py-5">
          <h1 className="display-2 text-white mb-4 animated slideInDown">
            Air Freight
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item">
                <Link to="/Home">Home</Link>
              </li>
              <li className="breadcrumb-item" aria-current="page">
              Air Freight
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* Page Header End */}
      <div className="container-fluid py-5 my-5">
        <div className="container pt-5">
          <div className="row g-5">
            <div className="text-center">
              <h2 className="text-primary"> Air Freight</h2>{" "}
            </div>
            <div
              className="col-lg-12 col-md-6 col-sm-12 wow fadeIn"
              data-wow-delay=".3s"
            >
              <img
                src="img/air.jpg"
                className="img-fluid  rounded mt-4"
                alt=""
                style={{
                  marginBottom: "1%",
                  width: "100%",
                  padding: "0px 120px",
                }}
              />
            </div>
            <div
              className="col-lg-12 col-md-6 col-sm-12 wow fadeIn"
              data-wow-delay=".5s"
            >
              <p>
                We collaborate with a global network of agents to facilitate the
                export of your cargo or small consignments via air
                transportation. Whether you have urgent or regular shipping
                needs, our air freight services offer faster, on-time, and
                cost-effective solutions with an extended reach and reduced risk
                of fraud.
                <br />
                Our comprehensive suite of air logistics solutions covers a wide
                range of services, including general, special, express, and
                intermodal air-freight options. We provide flexible service
                options such as 'door to door,' 'door to airport,' 'airport to
                door,' and 'airport to airport' deliveries.
                <br />
                As part of our services, we offer air freight consolidation to
                optimize the transportation of various-sized consignments. We
                ensure pre-space bookings with leading carriers to secure
                efficient delivery. Our expertise extends to handling perishable
                goods, hazardous cargo, and any other items that require fast
                and punctual delivery.
                <br />
                To keep our customers informed, we provide regular tracking
                updates for shipments. Additionally, we facilitate exhibition
                shipments under carnet and offer part charter and full charter
                services as required.
                <br />
                By choosing our air freight solutions, you can trust us to
                deliver your goods promptly and reliably, leveraging our
                extensive network, expertise, and dedication to customer
                satisfaction.
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
