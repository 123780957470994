import { useState } from "react";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Careers() {
  const [fullname, setFullname] = useState("");
  const [emailid, setEmailid] = useState("");
  const [phone, setPhone] = useState("");
  const [qualification, setQualification] = useState("");
  const [position, setPosition] = useState("");
  const [coverletter, setCoverletter] = useState("");
  const [attachfile, setAttachfile] = useState("");
  const [result, setResult] = useState("");

  const handlefullnameChange = (e) => {
    setFullname(e.target.value);
  };
  const handleemailidChange = (e) => {
    setEmailid(e.target.value);
  };
  const handlephoneChange = (e) => {
    setPhone(e.target.value);
  };
  const handlequalificationChange = (e) => {
    setQualification(e.target.value);
  };
  const handlepositionChange = (e) => {
    setPosition(e.target.value);
  };
  const handlecoverletterChange = (e) => {
    setCoverletter(e.target.value);
  };

  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleSubmit = (e) => {
    console.log(selectedFile);

    e.preventDefault();
    const form = $(e.target);
    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        setResult(data);
      },
    });
  };
  return (
    <>
      <Header />

      {/* Page Header Start */}
      <div className="container-fluid page-header py-5">
        <div className="container text-center py-5">
          <h1 className="display-2 text-white mb-4 animated slideInDown">
          Careers
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item">
                <Link to="/Home">Home</Link>
              </li>
              <li className="breadcrumb-item" aria-current="page">
                Careers
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* Page Header End */}

      {/* Contact Start */}
      <div className="container-fluid py-5 mt-5">
        <div className="container py-5">
          <div
            className="text-center mx-auto pb-5 wow fadeIn"
            data-wow-delay=".3s"
            style={{ maxWidth: "600px" }}
          >
            <h5 className="text-primary">Careers</h5>
            <h1 className="mb-3">Get opportunities at {companyname}</h1>
          </div>
          <div className="contact-detail position-relative p-5">
            <div className="row g-5">
              <div className="col-lg-12 wow fadeIn" data-wow-delay=".5s">
                <div className="p-5 rounded contact-form">
                <form
              action="/php/thankyou-careers.php"
              method="post"
              onSubmit={(event) => handleSubmit(event)}
            >
            <div className="row p-2">
              <div className="col-md-4">
                <div className="form-floating">
                  <input onChange={(event) => handlefullnameChange(event)} type="text" style={{width: '100%', padding: '10px', margin: '6px 0'}} value={fullname} id="fullname" name="fullname" placeholder="Your Name" />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-floating">
                  <input onChange={(event) => handleemailidChange(event)} type="email" style={{width: '100%', padding: '10px', margin: '6px 0'}} value={emailid} id="emailid" name="emailid" placeholder="Your Email" />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-floating">
                  <input onChange={(event) => handlephoneChange(event)} type="text" style={{width: '100%', padding: '10px', margin: '6px 0'}} value={phone} id="handlephoneChange" name="phone" placeholder="Phone Number" />
                </div>
              </div>
            </div>
            <label>Highest Qualification</label>
            <div className="col-12 p-2">
              <div className="form-floating">
                <input onChange={(event) => handlequalificationChange(event)} type="text" style={{width: '100%', padding: '10px', margin: '6px 0'}} value={qualification} id="qualification" name="qualification" placeholder="qualification" required />
              </div>
            </div>
            <label>Position Applied for</label>
            <div className="col-12 p-2">
              <div className="form-floating">
                <input onChange={(event) => handlepositionChange(event)} type="text" style={{width: '100%', padding: '10px', margin: '6px 0'}} value={position} id="position" name="position" placeholder="position" required />
              </div>
            </div>
            <label>Add cover letter</label>
            <div className="col-12 p-2">
              <div className="form-floating">
                <textarea onChange={(event) => handlecoverletterChange(event)} style={{width: '100%', padding: '10px', margin: '6px 0'}} placeholder="Leave a message here" value={coverletter} id="coverletter" name="coverletter" required defaultValue={""} />
              </div>
            </div>
                    <div className="text-start">
                      <button
                        className="btn bg-primary text-white py-3 px-5"
                        name="submitcareers"
                        defaultValue="SEND"
                        type="submit"
                      >
                        Apply
                      </button>
                    </div>
                    <br />
                  </form>

                  <h1>{result}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Contact End */}

      <Footer />
    </>
  );
}
