import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function FMCG() {
  return (
    <>
      <Header />

      {/* Page Header Start */}
      <div className="container-fluid page-header py-5">
        <div className="container text-center py-5">
          <h1 className="display-2 text-white mb-4 animated slideInDown">
            FMCG
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item">
                <Link to="/Home">Home</Link>
              </li>
              <li className="breadcrumb-item" aria-current="page">
                FMCG
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* Page Header End */}
      <div className="container-fluid py-5 my-5">
        <div className="container pt-5">
          <div className="row g-5">
            <div
              className="col-lg-5 col-md-6 col-sm-12 wow fadeIn"
              data-wow-delay=".3s"
            >
              <img
                src="img/fmcg.webp"
                className="img-fluid  rounded mt-4"
                alt=""
                style={{ marginBottom: "25%" }}
              />
            </div>
            <div
              className="col-lg-7 col-md-6 col-sm-12 wow fadeIn"
              data-wow-delay=".5s"
            >
              <h5 className="text-primary">FMCG</h5>
              <p>
                The FMCG and Food & Beverages industries have experienced rapid
                growth in recent years, presenting unique challenges for
                companies operating in these sectors. One pressing challenge is
                the increasing volumes of goods each year, which often exceeds
                the capabilities of existing warehouse and transportation
                systems. Maintaining high fill rates is another challenging
                issue due to volatile demand and supply dynamics. Additionally,
                the presence of multiple channels such as General Trade, Modern
                Trade, and E-commerce, along with the perishability of products
                and the need for FIFO management, further complicates
                operations.
                <br />
                To address these challenges, {companyname} offers integrated
                logistics solutions. We provide a technology-enabled supply
                chain aggregator platform that helps balance demand and supply
                within specified timelines. This platform aims to prevent sales
                losses resulting from product unavailability in various markets.
                By leveraging advanced technology and our expertise in
                logistics, we optimize inventory management, improve visibility,
                and ensure efficient distribution, ultimately helping our
                clients overcome the complexities of FMCG and Food & Beverages
                operations.
                <br />
                At {companyname}, we understand the importance of timely delivery,
                maintaining product freshness, and maximizing fill rates.
                Through our integrated logistics solutions, we strive to enhance
                operational efficiency, minimize sales loss, and support the
                growth and success of our clients in the FMCG and Food &
                Beverages industries.
                <br />
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
