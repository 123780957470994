import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Supply() {
  return (
    <>
      <Header />

      {/* Page Header Start */}
      <div className="container-fluid page-header py-5">
        <div className="container text-center py-5">
          <h1 className="display-2 text-white mb-4 animated slideInDown">
            Supply Chain Management
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item">
                <Link to="/Home">Home</Link>
              </li>
              <li className="breadcrumb-item" aria-current="page">
                Supply Chain Management
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* Page Header End */}
      <div className="container-fluid py-5 my-5">
        <div className="container pt-5">
          <div className="row g-5">
            <div className="text-center">
              <h2 className="text-primary"> Supply Chain Management</h2>{" "}
            </div>
            <div
              className="col-lg-12 col-md-6 col-sm-12 wow fadeIn"
              data-wow-delay=".3s"
            >
              <img
                src="img/supply.jpg"
                className="img-fluid  rounded mt-4"
                alt=""
                style={{
                  marginBottom: "1%",
                  width: "100%",
                  padding: "0px 120px",
                }}
              />
            </div>
            <div
              className="col-lg-12 col-md-6 col-sm-12 wow fadeIn"
              data-wow-delay=".5s"
            >
              <p>
                The advent of globalization has brought about significant
                changes in the business landscape. It has opened up
                opportunities to expand operations across different geographies,
                allowing businesses to reach a wider audience and achieve
                scalability. To capitalize on these opportunities, companies
                require smarter and more focused global supply chain models.{" "}
                <br />
                Automated supply chain models have rapidly gained popularity,
                offering a competitive edge through efficient economic
                flowcharts and up-to-date products. However, managing a global
                supply chain is a complex task as it involves seamlessly
                coordinating an ever-expanding network of stakeholders.
                <br />
                For organizations, it is crucial to address inefficiencies at
                access points, ensuring that external users can access internal
                data seamlessly. Moreover, shareholders should be able to
                retrieve valuable information while maintaining cost-effective
                pricing, ensuring regulatory compliance, and making informed
                supplier selections.
                <br />
                To achieve these objectives, supply chains need holistic
                insights into their operations. This comprehensive understanding
                allows for improved efficiency in end-user product delivery. By
                integrating advanced technologies and data-driven strategies,
                businesses can optimize their supply chains, enhance operational
                efficiency, and ultimately deliver products to end-users more
                effectively.
                <br />A well-managed and insightful supply chain provides
                businesses with a competitive advantage in today's globalized
                marketplace, enabling them to meet customer demands, drive
                growth, and achieve long-term success.
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
