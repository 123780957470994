import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Ground() {
  return (
    <>
      <Header />

      {/* Page Header Start */}
      <div className="container-fluid page-header py-5">
        <div className="container text-center py-5">
          <h1 className="display-2 text-white mb-4 animated slideInDown">
          Ground Transportation
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item">
                <Link to="/Home">Home</Link>
              </li>
              <li className="breadcrumb-item" aria-current="page">
              Ground Transportation
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* Page Header End */}
      <div className="container-fluid py-5 my-5">
        <div className="container pt-5">
          <div className="row g-5">
            <div className="text-center">
              <h2 className="text-primary"> Ground Transportation</h2>{" "}
            </div>
            <div
              className="col-lg-12 col-md-6 col-sm-12 wow fadeIn"
              data-wow-delay=".3s"
            >
              <img
                src="img/road.png"
                className="img-fluid  rounded mt-4"
                alt=""
                style={{
                  marginBottom: "1%",
                  width: "100%",
                  padding: "0px 120px",
                }}
              />
            </div>
            <div
              className="col-lg-12 col-md-6 col-sm-12 wow fadeIn"
              data-wow-delay=".5s"
            >
              <p>
                For merchandisers and business managers, it is crucial to have a
                thorough understanding of established Export-Import (EXIM)
                procedures aligned with global regulations and movements. Our
                services ensure that your cargo follows these procedures
                seamlessly. We strategically determine the most efficient and
                cost-effective routes for dispatching your cargo, considering
                fixed points of dispatch. Additionally, we adapt to changing
                terms and conditions to provide you with the best logistics and
                supply-chain solutions.
                <br />
                In terms of domestic movements, we offer transportation services
                using 20 Ft. and 40 Ft. trailers, catering to the specific needs
                of each client. Our transportation services are provided at
                competitive prices while adhering to agreed-upon parameters.
                It's worth noting that our domestic workers receive equal rights
                and benefits as per the new rules and regulations set by the
                government.
                <br />
                By entrusting us with your EXIM procedures and domestic
                movements, you can rely on our expertise in global logistics and
                supply chains to ensure compliance, cost-effectiveness, and
                efficient transportation of your goods.
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
