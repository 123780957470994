import ReactDOM from "react-dom";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from './pages/Home';
import Contact from "./pages/Contact";
import Sea from "./pages/Sea";
import Rail from "./pages/Rail";
import Air from "./pages/Air";
import Ground from "./pages/Ground";
import Warehousing from "./pages/Warehousing";
import Project from "./pages/Project";
import Supply from "./pages/Supply";
import Door from "./pages/Door";
import Customs from "./pages/Customs";

import Automotive from "./pages/Automotive";
import Consumer from "./pages/Consumer";
import Metals from "./pages/Metals";
import FMCG from "./pages/FMCG";
import Pharma from "./pages/Pharma";
import Chemicals from "./pages/Chemicals";
import Careers from "./pages/Careers";
import Getquote from "./pages/Getquote";
import About from "./pages/About";
import Services from "./pages/Services";
import Industries from "./pages/Industries";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";



import Signin from "./admin/Signin";


export default function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Home" element={<Home />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/Sea" element={<Sea/>} />
          <Route path="/Rail" element={<Rail />} />
          <Route path="/Air" element={<Air />} />
          <Route path="/Ground" element={<Ground />} />
          <Route path="/Warehousing" element={<Warehousing />} />
          <Route path="/Customs" element={<Customs />} />
          <Route path="/Getquote" element={<Getquote />} />
          <Route path="/Automotive" element={<Automotive />} />
          <Route path="/Consumer" element={<Consumer />} />
          <Route path="/Metals" element={<Metals />} />
          <Route path="/FMCG" element={<FMCG />} />
          <Route path="/Pharma" element={<Pharma />} />
          <Route path="/Chemicals" element={<Chemicals />} />
          <Route path="/Careers" element={<Careers />} />
          <Route path="/About" element={<About />} />
          <Route path="/Services" element={<Services />} />
          <Route path="/Project" element={<Project />} />
          <Route path="/Supply" element={<Supply />} />
          <Route path="/Door" element={<Door />} />
          <Route path="/Industries" element={<Industries />} />
          <Route path="/Signin" element={<Signin />} />
          <Route path="/Privacy" element={<Privacy />} />
          <Route path="/Terms" element={<Terms />} />

        </Routes>
      </Router>
    </div>
  );
}

ReactDOM.render(<App />, document.getElementById('root'));