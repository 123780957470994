import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";


import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Warehousing() {
  return (
    <>
      <Header />

      {/* Page Header Start */}
      <div className="container-fluid page-header py-5">
        <div className="container text-center py-5">
          <h1 className="display-2 text-white mb-4 animated slideInDown">
            Warehousing
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item">
                <Link to="/Home">Home</Link>
              </li>
              <li className="breadcrumb-item" aria-current="page">
                Warehousing
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* Page Header End */}
      <div className="container-fluid py-5 my-5">
        <div className="container pt-5">
          <div className="row g-5">
            <div className="text-center">
              <h2 className="text-primary"> Warehousing</h2>{" "}
            </div>
            <div
              className="col-lg-12 col-md-6 col-sm-12 wow fadeIn"
              data-wow-delay=".3s"
            >
              <img
                src="img/warehousing.png"
                className="img-fluid  rounded mt-4"
                alt=""
                style={{
                  marginBottom: "1%",
                  width: "100%",
                  padding: "0px 120px",
                }}
              />
            </div>
            <div
              className="col-lg-12 col-md-6 col-sm-12 wow fadeIn"
              data-wow-delay=".5s"
            >
              <p>
                We deal with extreme network of warehouses for our clients.
                With our advanced Warehousing Management Software, we can easily
                track your consignment's details within the container terminal
                and warehouse. Our facilities are under 24/7 CCTV surveillance,
                controlled by centralized IP systems to ensure comprehensive
                security.
                <br />
                You can stay updated on the arrival and departure of your
                consignments through our notification system, providing
                real-time information. We also provide EXIM and ODC parcel
                buffers until the scheduled ship arrival. Our dedicated team at
                the warehouses serves as our valuable asset, ensuring smooth
                supply chain processes both within and beyond the premises.
                <br />
                Key features of our warehousing services include open and closed
                warehousing options, Direct Port Delivery (DPD) facility, and a
                range of equipment such as reach stackers, forklifts, and hydras
                to facilitate efficient operations. Our facilities maintain a
                secure area for insurance and cargo protection, and we have a
                readily available labor force to handle your requirements.
                Additionally, we have our own fleet of vehicles for seamless
                transportation.
                <br />
                By leveraging our warehousing and container handling services,
                you can benefit from our cost-effective solutions,
                state-of-the-art facilities, advanced software, and dedicated
                team. We aim to streamline your logistics processes and provide
                you with peace of mind regarding the safety and efficiency of
                your cargo.
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
