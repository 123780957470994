import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Door() {
  return (
    <>
      <Header />

      {/* Page Header Start */}
      <div className="container-fluid page-header py-5">
        <div className="container text-center py-5">
          <h1 className="display-2 text-white mb-4 animated slideInDown">
            Door to Door
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item">
                <Link to="/Home">Home</Link>
              </li>
              <li className="breadcrumb-item" aria-current="page">
                Door to Door
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* Page Header End */}
      <div className="container-fluid py-5 my-5">
        <div className="container pt-5">
          <div className="row g-5">
            <div className="text-center">
              <h2 className="text-primary"> Door to Door</h2>{" "}
            </div>
            <div
              className="col-lg-12 col-md-6 col-sm-12 wow fadeIn"
              data-wow-delay=".3s"
            >
              <img
                src="img/door.jpg"
                className="img-fluid  rounded mt-4"
                alt=""
                style={{
                  marginBottom: "1%",
                  width: "100%",
                  padding: "0px 120px",
                }}
              />
            </div>
            <div
              className="col-lg-12 col-md-6 col-sm-12 wow fadeIn"
              data-wow-delay=".5s"
            >
              <p>
                <br /> Our door-to-door logistics solutions are designed to
                alleviate the challenges associated with transportation and
                logistics. Leveraging our extensive global network and
                expertise, we offer flexible pricing options to seamlessly
                deliver your load from the port to the factory of exporters and
                importers.
                <br />
                <br />
                Key features of our door-to-door logistics solutions include:
                <br />
                • Entire logistics solution: We provide end-to-end logistics
                services, ensuring a smooth and hassle-free experience for our
                customers.
                <br />
                • From Buyers location to Sellers destination: Our services
                cover the entire journey, starting from the location of the
                buyer and extending all the way to the destination of the
                seller.
                <br />
                • Unparalleled synergy for cost efficiency: By integrating
                various logistics components and optimizing processes, we
                achieve exceptional synergy that translates into cost-effective
                solutions for our clients.
                <br />
                • Timely deliveries: We understand the importance of timely
                delivery and work diligently to ensure that your shipments reach
                their destinations within the agreed-upon timeframes.
                <br />
                With our door-to-door logistics solutions, you can enjoy the
                convenience of a comprehensive service that covers the entire
                logistics process, from collection to delivery. We are committed
                to providing timely and cost-efficient deliveries, allowing you
                to focus on your core business while leaving the logistics
                complexities to us.
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
