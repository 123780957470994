import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Consumer() {
  return (
    <>
      <Header />

      {/* Page Header Start */}
      <div className="container-fluid page-header py-5">
        <div className="container text-center py-5">
          <h1 className="display-2 text-white mb-4 animated slideInDown">
            Consumer Durables
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item">
                <Link to="/Home">Home</Link>
              </li>
              <li className="breadcrumb-item" aria-current="page">
                Consumer Durables
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* Page Header End */}
      <div className="container-fluid py-5 my-5">
        <div className="container pt-5">
          <div className="row g-5">
            <div
              className="col-lg-5 col-md-6 col-sm-12 wow fadeIn"
              data-wow-delay=".3s"
            >
              <img
                src="img/consumer.jpg"
                className="img-fluid  rounded mt-4"
                alt=""
                style={{ marginBottom: "25%" }}
              />
            </div>
            <div
              className="col-lg-7 col-md-6 col-sm-12 wow fadeIn"
              data-wow-delay=".5s"
            >
              <h5 className="text-primary">Consumer Durables</h5>
              <p>
                The consumer durables, electronics, and hi-tech sectors face
                distinct supply chain challenges, including low margins, short
                product life cycles, and the issue of obsolescence. Furthermore,
                these industries are impacted by factors such as product
                proliferation, demand volatility, and seasonality, which pose
                significant threats. <br />
                In India, an emerging economy, market dynamics are complex due
                to the presence of multiple channels and their varying levels of
                penetration. While traditional general trade distribution has
                historically dominated the industry, the sector has experienced
                a significant surge in e-commerce and modern omnichannel
                retailing. Consequently, customer expectations have also risen,
                presenting additional challenges.
                <br />
                At {companyname}, we are well-prepared to navigate the market
                uncertainties and provide expert solutions tailored to the
                unique needs of the consumer durables, electronics, and hi-tech
                sectors. Our services encompass storage and handling, efficient
                inventory management, and customized processes designed to
                handle the ever-changing demands of the industry.
                <br />
                By leveraging our expertise and experience, we help our clients
                tackle the complexities of these sectors. Our aim is to optimize
                supply chain operations, enhance efficiency, and meet customer
                expectations in an industry characterized by fluctuating demands
                and evolving market dynamics.
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
