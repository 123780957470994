import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Sea() {
  return (
    <>
      <Header />

      {/* Page Header Start */}
      <div className="container-fluid page-header py-5">
        <div className="container text-center py-5">
          <h1 className="display-2 text-white mb-4 animated slideInDown">
            Sea Freight
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item">
                <Link to="/Home">Home</Link>
              </li>
              <li className="breadcrumb-item" aria-current="page">
                Sea Freight
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* Page Header End */}
      <div className="container-fluid py-5 my-5">
        <div className="container pt-5">
          <div className="row g-5">
            <div className="text-center">
              <h2 className="text-primary"> Sea Freight</h2>{" "}
            </div>
            <div
              className="col-lg-12 col-md-6 col-sm-12 wow fadeIn"
              data-wow-delay=".3s"
            >
              <img
                src="img/sea.jpg"
                className="img-fluid  rounded mt-4"
                alt=""
                style={{
                  marginBottom: "1%",
                  width: "100%",
                  padding: "0px 120px",
                }}
              />
            </div>
            <div
              className="col-lg-12 col-md-6 col-sm-12 wow fadeIn"
              data-wow-delay=".5s"
            >
              <p>
                Sea freight has played a pivotal role in driving the development
                of the commerce industry. It offers significant advantages such
                as cost-effectiveness, high load capacity of vessels, and fewer
                restrictions compared to other modes of transportation. If you
                have valuable cargo, sea freight can be the ideal solution for
                your shipping needs.
                <br />
                At our company, we boast a global network and a team of
                experienced professionals who are well-versed in providing the
                most suitable shipping routes for your cargo. We prioritize
                timely and secure delivery, ensuring that your cargo reaches its
                destination on schedule. Additionally, we simplify the process
                of de-stuffing and stuffing your cargo, making it more efficient
                and hassle-free.
                <br />
                To cater to various requirements, we have invested in optimal
                Temperature Control Containers. These containers are
                specifically designed to accommodate perishable and hazardous
                goods, ensuring that they are transported in optimal conditions.
                We offer 20 Ft. and 40 Ft. containers to meet the diverse needs
                of our clients.
                <br />
                By leveraging our sea freight services, you can rely on our
                expertise, global network, and specialized containers to
                transport your valuable cargo with safety, efficiency, and
                on-time delivery.
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
