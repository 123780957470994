import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "./Companyinfo";

const Footer = () => {
  return (
    <>
      {/* Footer Start */}
      <div
        className="container-fluid footer bg-dark wow fadeIn"
        data-wow-delay=".3s"
      >
        <div className="container pt-5 pb-4">
          <div className="row g-5">
            <div className="col-lg-3 col-md-6">
            <Link to="index.html" className="navbar-brand">
              <img src="img/logo.png" className="img-fluid" alt="Second slide" style={{height:'100px'}} />
              </Link>
              <p className="mt-4 text-light">
              Nexrise International has rapidly expanded its global network from humble beginnings. Our unwavering vision centers around delivering exceptional quality in logistics services. At Nexrise International, we achieve the pinnacle of customer satisfaction by combining human expertise with cutting-edge logistics technology.
              </p>
             
            </div>
            <div className="col-lg-3 col-md-6">
              <a href className="h3 text-secondary">
                Short Link
              </a>
              <div className="mt-4 d-flex flex-column short-link">
              <Link to="/Home" className="mb-2 text-white "><i className="fas fa-angle-right text-secondary me-2" />Home</Link>
                  <Link to="/About" className="mb-2 text-white"> <i className="fas fa-angle-right text-secondary me-2" />About Us </Link>
                  <Link to="/Services" className="mb-2 text-white"> <i className="fas fa-angle-right text-secondary me-2" />Services</Link>

                  <Link to="/Industries" className="mb-2 text-white"> <i className="fas fa-angle-right text-secondary me-2" />Industries</Link>

                  <Link to="/Careers" className="mb-2 text-white"> <i className="fas fa-angle-right text-secondary me-2" />Careers</Link>

                  <Link to="/Contact" className="mb-2 text-white"> <i className="fas fa-angle-right text-secondary me-2" />Contact</Link>
                  <Link to="/Getquote" className="mb-2 text-white"> <i className="fas fa-angle-right text-secondary me-2" />Request for Quote</Link>

              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <a href className="h3 text-secondary">
                Our Services
              </a>
              <div className="mt-4 d-flex flex-column help-link">
              <Link to="/Sea" className="mb-2 text-white"> <i className="fas fa-angle-right text-secondary me-2" />Sea Freight</Link>
                      <Link to="/Air" className="mb-2 text-white"> <i className="fas fa-angle-right text-secondary me-2" />Air Freight</Link>
                      <Link to="/Rail" className="mb-2 text-white"> <i className="fas fa-angle-right text-secondary me-2" />Rail Freight</Link>
                      <Link to="/Ground" className="mb-2 text-white"> <i className="fas fa-angle-right text-secondary me-2" />Ground Transportation</Link>
                      <Link to="/Warehousing" className="mb-2 text-white"> <i className="fas fa-angle-right text-secondary me-2" />Warehousing</Link>
                      <Link to="/Door" className="mb-2 text-white"> <i className="fas fa-angle-right text-secondary me-2" />Door to Door</Link>
                      <Link to="/Project" className="mb-2 text-white"> <i className="fas fa-angle-right text-secondary me-2" />Project Cargo Logistics</Link>
                      <Link to="/Customs" className="mb-2 text-white"> <i className="fas fa-angle-right text-secondary me-2" />Customs Clearance & Documentation</Link>
              <Link to="/Supply" className="mb-2 text-white"> <i className="fas fa-angle-right text-secondary me-2" />Supply Chain Management</Link>

              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <a href="#" className="h3 text-secondary">
                Contact Us
              </a>
              <div className="text-white mt-4 d-flex flex-column contact-link">
                <a
                  href
                  className="pb-3 text-light border-bottom border-primary"
                >
                  <i className="fas fa-map-marker-alt text-secondary me-2" />{companyaddress}
                  
                </a>
                <a
                  href
                  className="py-3 text-light border-bottom border-primary"
                >
                  <i className="fas fa-phone-alt text-secondary me-2" />{companynumber}
                </a>
                <a
                  href
                  className="py-3 text-light border-bottom border-primary"
                >
                  <i className="fas fa-envelope text-secondary me-2" />{" "}
                 {companyemail}
                </a>
              </div>
            </div>
          </div>
          <hr className="text-light mt-5 mb-4" />
          <div className="row">
            <div className="col-md-6 text-center text-md-start">
              <span className="text-light">
              <Link to="index.html" className="navbar-brand">
              <i className="fas fa-copyright text-secondary me-2" /> {companyname}
              </Link>, All right reserved.
              </span>
            </div>
            <div className="col-md-2 text-center text-md-end">
             
            </div>
            <div className="col-md-2 text-center text-md-end">
              <span className="text-light">
              <Link to="/Privacy" className="text-secondary">Privacy Policy  </Link>
                
               
              </span>
            </div>
            <div className="col-md-2 text-center text-md-end">
              <span className="text-light">
              <Link to="/Terms" className="text-secondary">Terms & Conditions  </Link>
                
               
              </span>
            </div>
          </div>
        </div>
      </div>
      {/* Footer End */}
      {/* Back to Top */}
      <Link
        to="/Home"
        className="btn btn-secondary btn-square rounded-circle back-to-top"
      >
        <i className="fa fa-arrow-up text-white" />
      </Link>
      <Outlet />
    </>
  );
};

export default Footer;
