import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Automotive() {
  return (
    <>
      <Header />

      {/* Page Header Start */}
      <div className="container-fluid page-header py-5">
        <div className="container text-center py-5">
          <h1 className="display-2 text-white mb-4 animated slideInDown">
          Automotive
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item">
                <Link to="/Home">Home</Link>
              </li>
              <li className="breadcrumb-item" aria-current="page">
                Automotive
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* Page Header End */}
      <div className="container-fluid py-5 my-5">
        <div className="container pt-5">
          <div className="row g-5">
            <div
              className="col-lg-5 col-md-6 col-sm-12 wow fadeIn"
              data-wow-delay=".3s"
            >
              <img
                src="img/automotive.jpg"
                className="img-fluid  rounded mt-4"
                alt=""
                style={{ marginBottom: "25%" }}
              />
            </div>
            <div
              className="col-lg-7 col-md-6 col-sm-12 wow fadeIn"
              data-wow-delay=".5s"
            >
              <h5 className="text-primary">Automotive</h5>
              <p>
                 The automotive supply chain is a highly intricate
                ecosystem that can significantly influence the success of a
                business. It faces challenges such as the continuous launch of
                new models with multiple variants, changing consumer demands,
                and disruptive trends. These factors directly impact the supply
                chain network for raw materials, parts, and finished
                automobiles. <br />
                At {companyname}, we understand the complexities of the automotive
                supply chain, and we have a dedicated team of experts who
                collaborate closely with our automotive clients. We offer
                integrated, technology-enabled solutions for spare-parts
                warehousing and transportation. Our advanced technology provides
                enhanced visibility within the warehouse and enables track 'n
                trace capabilities during transit. <br />
                We pride ourselves on providing customized and flexible
                operations tailored to the unique requirements of our automotive
                clients. Our services encompass efficient inventory management,
                cross-docking facilities, and a range of value-added services
                such as kitting. Through our comprehensive solutions, we strive
                to optimize operations and enhance efficiency within the
                automotive supply chain, ultimately driving value for our
                clients.
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
