import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Rail(){
    return(
      <>
      <Header />

      {/* Page Header Start */}
      <div className="container-fluid page-header py-5">
        <div className="container text-center py-5">
          <h1 className="display-2 text-white mb-4 animated slideInDown">
            Rail Freight
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item">
                <Link to="/Home">Home</Link>
              </li>
              <li className="breadcrumb-item" aria-current="page">
              Rail Freight
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* Page Header End */}
      <div className="container-fluid py-5 my-5">
        <div className="container pt-5">
          <div className="row g-5">
            <div className="text-center">
              <h2 className="text-primary"> Rail Freight</h2>{" "}
            </div>
            <div
              className="col-lg-12 col-md-6 col-sm-12 wow fadeIn"
              data-wow-delay=".3s"
            >
              <img
                src="img/rail.jpg"
                className="img-fluid  rounded mt-4"
                alt=""
                style={{
                  marginBottom: "1%",
                  width: "100%",
                  padding: "0px 120px",
                }}
              />
            </div>
            <div
              className="col-lg-12 col-md-6 col-sm-12 wow fadeIn"
              data-wow-delay=".5s"
            >
              <p>
              {companyname} specializes in Rail Freight Forwarding services, which encompass both conventional transportation in covered/open wagons and containerized rail transport. We work in close association with Indian Railways to provide efficient and reliable services to our customers.<br/>

Our Rail Freight Forwarding services include the aggregation of loads from multiple customers, allowing for the movement of bulk volumes in a more streamlined manner. By leveraging our partnerships and collaborations with Indian Railways, we are able to participate in railways incentive schemes and bulk discount arrangements, ultimately leading to cost advantages for our customers.<br/>

Whether you require transportation in covered/open wagons or containerized rail transport, we have the expertise and resources to cater to your specific needs. Our aim is to provide efficient rail freight solutions, ensuring the timely and secure transportation of your goods. Partnering with us allows you to tap into the benefits of rail transportation, taking advantage of cost advantages and leveraging the extensive rail network for the movement of your cargo.
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
    )
}