import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "./Companyinfo";

const Header = () => {
  return (
    <>
     
       
        {/* Topbar Start */}
        <div className="container-fluid bg-dark py-2 d-none d-md-flex">
          <div className="container">
            <div className="d-flex justify-content-between topbar">
              <div className="top-info">
                <small className="me-3 text-white-50"><Link to="#"><i className="fas fa-map-marker-alt me-2 text-secondary" /></Link>{companyaddress}</small>
               
              </div>
              <div className="top-link">
               
              </div>
            </div>
          </div>
        </div>
        {/* Topbar End */}
        {/* Navbar Start */}
        <div className="container-fluid ">
          <div className="container">
            <nav className="navbar navbar-dark navbar-expand-lg py-0">
              <Link to="index.html" className="navbar-brand">
              <img src="img/logo.png" className="img-fluid" alt="Second slide" style={{height:'100px'}} />
              </Link>
              <button type="button" className="navbar-toggler me-0" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                <span className="navbar-toggler-icon" />
              </button>
              <div className="collapse navbar-collapse bg-transparent" id="navbarCollapse">
                <div className="navbar-nav ms-auto mx-xl-auto p-0">
                  <Link to="/Home" className="nav-item nav-link active text-secondary">Home</Link>
                  <Link to="/About" className="nav-item nav-link">About Us </Link>
                 
                  <div className="nav-item dropdown">
                    <Link to="/Services" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Our Services</Link>
                    <div className="dropdown-menu rounded">
                      <Link to="/Sea" className="dropdown-item">Sea Freight</Link>
                      <Link to="/Air" className="dropdown-item">Air Freight</Link>
                      <Link to="/Rail" className="dropdown-item">Rail Freight</Link>
                      <Link to="/Ground" className="dropdown-item">Ground Transportation</Link>
                      <Link to="/Warehousing" className="dropdown-item">Warehousing</Link>
                      <Link to="/Door" className="dropdown-item">Door to Door</Link>
                      <Link to="/Project" className="dropdown-item">Project Cargo Logistics</Link>
                      <Link to="/Customs" className="dropdown-item">Customs Clearance & Documentation</Link>
                      <Link to="/Supply" className="dropdown-item">Supply Chain Management</Link>


                    </div>
                  </div>
                  <div className="nav-item dropdown">
                    <Link to="/Services" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Our Industries</Link>
                    <div className="dropdown-menu rounded">
                      <Link to="/Automotive" className="dropdown-item">Automotive</Link>
                      <Link to="/Pharma" className="dropdown-item">Pharma</Link>
                      <Link to="/Metals" className="dropdown-item">Metals & Mining</Link>
                      <Link to="/Chemicals" className="dropdown-item">Chemicals, Oil & Gas</Link>
                      <Link to="/FMCG" className="dropdown-item">FMCG</Link>
                      <Link to="/Consumer" className="dropdown-item">Consumer Durables</Link>

                   

                    </div>
                  </div>
                  <Link to="/Careers" className="nav-item nav-link">Careers</Link>

                  <Link to="/Contact" className="nav-item nav-link">Contact</Link>
                </div>
              </div>
              <div className="d-none d-xl-flex flex-shirink-0">
               
              <Link
                to="/Getquote"
                className="btn btn-secondary rounded-pill px-5 py-3 text-white"
              >
                Request For Quote
              </Link>
               
              </div>
            </nav>
          </div>
        </div>
        {/* Navbar End */}

      <Outlet />
    </>
  );
};

export default Header;
