import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Chemicals() {
  return (
    <>
      <Header />

      {/* Page Header Start */}
      <div className="container-fluid page-header py-5">
        <div className="container text-center py-5">
          <h1 className="display-2 text-white mb-4 animated slideInDown">
          Chemicals, Oil & Gas
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item">
                <Link to="/Home">Home</Link>
              </li>
              <li className="breadcrumb-item" aria-current="page">
                Chemicals, Oil & Gas
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* Page Header End */}
      <div className="container-fluid py-5 my-5">
        <div className="container pt-5">
          <div className="row g-5">
            <div
              className="col-lg-5 col-md-6 col-sm-12 wow fadeIn"
              data-wow-delay=".3s"
            >
              <img
                src="img/chemicals.jpg"
                className="img-fluid  rounded mt-4"
                alt=""
                style={{ marginBottom: "25%" }}
              />
            </div>
            <div
              className="col-lg-7 col-md-6 col-sm-12 wow fadeIn"
              data-wow-delay=".5s"
            >
              <h5 className="text-primary"> Chemicals, Oil & Gas</h5>
              <p>
                At {companyname}, we recognize the distinctive logistical challenges
                and large shipment requirements of the chemicals, petrochemical,
                and oil & gas sectors. With our extensive experience, we
                specialize in managing the transportation of these industries'
                products, ensuring compliance with regulations while maintaining
                the highest standards of safety and quality. <br />
                As a trusted logistics service provider, {companyname} is known for
                successfully meeting customer expectations and efficiently
                handling both hazardous and non-hazardous materials. Our
                comprehensive range of services encompasses port handling, road
                and rail transport, warehousing, shipping, and more. By
                integrating these services, we offer customized logistics
                solutions tailored to meet the unique needs of each client.{" "}
                <br />
                We have made substantial investments in state-of-the-art
                infrastructure, specialized equipment, and handling facilities
                to enhance cargo handling efficiency. This enables us to provide
                reliable and efficient logistics services for the chemicals,
                petrochemical, and oil & gas sectors. Our commitment to
                excellence and industry expertise has earned us the trust of
                clients in this sector.
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
