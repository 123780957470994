import { useState } from "react";
import { useHistory } from 'react-router-dom';
import $ from "jquery";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";


export default function Contact() {
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [result, setResult] = useState("");

  const handleFnameChange = (e) => {
    setFname(e.target.value);
  };
  const handlelnameChange = (e) => {
    setLname(e.target.value);
  };
   const handleemailChange = (e) => {
    setEmail(e.target.value);
  };
  const handlesubjectChange = (e) => {
    setSubject(e.target.value);
  }; 
  const handlemessageChange = (e) => {
    setMessage(e.target.value);
  }; 

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = $(e.target);
    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        setResult(data);
      },
    });
  };
  return (
    <>
      <Header />


        {/* Page Header Start */}
        <div className="container-fluid page-header py-5">
          <div className="container text-center py-5">
            <h1 className="display-2 text-white mb-4 animated slideInDown">Contact Us</h1>
            <nav aria-label="breadcrumb animated slideInDown">
              <ol className="breadcrumb justify-content-center mb-0">
                <li className="breadcrumb-item"><Link to="/Home">Home</Link></li>
                <li className="breadcrumb-item" aria-current="page">Contact</li>
              </ol>
            </nav>
          </div>
        </div>
        {/* Page Header End */}
 
        {/* Contact Start */}
        <div className="container-fluid py-5 mt-5">
          <div className="container py-5">
            <div className="text-center mx-auto pb-5 wow fadeIn" data-wow-delay=".3s" style={{maxWidth: '600px'}}>
              <h5 className="text-primary">Get In Touch</h5>
              <h1 className="mb-3">Contact us for any query</h1>
              <p className="mb-2">Write your message or query in the message box, providing as much relevant information as possible.</p>
            </div>
            <div className="contact-detail position-relative p-5">
              <div className="row g-5 mb-5 justify-content-center">
                <div className="col-xl-4 col-lg-6 wow fadeIn" data-wow-delay=".3s">
                  <div className="d-flex bg-light p-3 rounded">
                    <div className="flex-shrink-0 btn-square bg-secondary rounded-circle" style={{width: '64px', height: '64px'}}>
                      <i className="fas fa-map-marker-alt text-white" />
                    </div>
                    <div className="ms-3">
                      <h4 className="text-primary">Address</h4>
                     {companyaddress}
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-6 wow fadeIn" data-wow-delay=".5s">
                  <div className="d-flex bg-light p-3 rounded">
                    <div className="flex-shrink-0 btn-square bg-secondary rounded-circle" style={{width: '64px', height: '64px'}}>
                      <i className="fa fa-phone text-white" />
                    </div>
                    <div className="ms-3">
                      <h4 className="text-primary">Call Us</h4>
                      {companynumber}
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-6 wow fadeIn" data-wow-delay=".7s">
                  <div className="d-flex bg-light p-3 rounded">
                    <div className="flex-shrink-0 btn-square bg-secondary rounded-circle" style={{width: '64px', height: '64px'}}>
                      <i className="fa fa-envelope text-white" />
                    </div>
                    <div className="ms-3">
                      <h4 className="text-primary">Email Us</h4>
                      {companyemail}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row g-5">
                <div className="col-lg-6 wow fadeIn" data-wow-delay=".3s">
                  <div className="p-5 h-100 rounded contact-map">
                  <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3771.9083181313786!2d73.01500707224848!3d19.023761214227456!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c3bdd58924c9%3A0x123982a5f3399097!2s19a%2C%20Seawoods%20Bridge%2C%20Seawoods%20East%2C%20Nerul%20East%2C%20Sector%2019A%2C%20Nerul%2C%20Navi%20Mumbai%2C%20Maharashtra%20400706!5e0!3m2!1sen!2sin!4v1686121857951!5m2!1sen!2sin"
            width="100%"
            height="100%"
            style={{ border: 0 }}
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          />
                  </div>
                </div>
                <div className="col-lg-6 wow fadeIn" data-wow-delay=".5s">
                  <div className="p-5 rounded contact-form">
                  <form
                      action="/php/thankyou-contact.php "
                      method="post"
                      onSubmit={(event) => handleSubmit(event)}
                    >

                      <div className="styled-input agile-styled-input-top">
                        <input   onChange={(event) => handleFnameChange(event)}
                          type="text"
                          className
                          id="fname"
                          name="fname"
                          value={fname}
                          placeholder="Enter your first name"
                          style={{
                            width: "100%",
                            padding: "10px",
                            margin: "6px 0",
                          }}
                        />
                        <span />
                      </div>
                      <div className=" styled-input">
                        <input   onChange={(event) => handlelnameChange(event)}
                          type="text"
                          style={{
                            width: "100%",
                            padding: "10px",
                            margin: "6px 0",
                          }}
                          id="lname"
                          name="lname"
                          value={lname}
                          placeholder="Enter your last name"
                        />
                      </div>
                      <div className="styled-input">
                        
                        <input   onChange={(event) => handleemailChange(event)}
                          type="email"
                          style={{
                            width: "100%",
                            padding: "10px",
                            margin: "6px 0",
                          }}
                          id="email"
                          name="email"
                          value={email}
                          placeholder="Enter your Email"
                        />
                      </div>
                      <div className="styled-input">
                        <input   onChange={(event) => handlesubjectChange(event)}
                          type="text"
                          style={{
                            width: "100%",
                            padding: "10px",
                            margin: "6px 0",
                          }}
                          id="subject"
                          name="subject"
                          value={subject}
                          placeholder="Enter subject"
                        />
                      </div>
                      <div className="styled-input">
                        <textarea
                          style={{
                            width: "100%",
                            padding: "10px",
                            margin: "6px 0",
                          }}
                          id="message"
                          name="message"
                          rows={1}
                          placeholder="Enter Message"
                          defaultValue={""}
                          value={message}
                          onChange={(event) => handlemessageChange(event)}
                        />
                      </div>
                      <div className="text-start">
                      <input type="submit" className="btn bg-primary text-white py-3 px-5" name="submit" defaultValue="SEND"/ >Send Message
                    </div>
                      <br />
                    </form>
                <h1>{result}</h1>
                   

                  </div>
                </div>

              </div>
            </div>
          </div> 
        </div>
        {/* Contact End */}
       

      <Footer />
    </>
  );
}
