import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pharma() {
  return (
    <>
      <Header />

      {/* Page Header Start */}
      <div className="container-fluid page-header py-5">
        <div className="container text-center py-5">
          <h1 className="display-2 text-white mb-4 animated slideInDown">
            Pharma Industry
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item">
                <Link to="/Home">Home</Link>
              </li>
              <li className="breadcrumb-item" aria-current="page">
                Pharma Industry
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* Page Header End */}
      <div className="container-fluid py-5 my-5">
        <div className="container pt-5">
          <div className="row g-5">
            <div
              className="col-lg-5 col-md-6 col-sm-12 wow fadeIn"
              data-wow-delay=".3s"
            >
              <img
                src="img/pharma.jpg"
                className="img-fluid  rounded mt-4"
                alt=""
                style={{ marginBottom: "25%" }}
              />
            </div>
            <div
              className="col-lg-7 col-md-6 col-sm-12 wow fadeIn"
              data-wow-delay=".5s"
            >
              <h5 className="text-primary">Pharma Industry</h5>
              <p>
                The pharmaceutical industry and its supply chains are highly
                globalized, with import-export connections playing a crucial
                role. Unlike other industries, pharmaceutical and healthcare
                products require precise and efficient logistics capabilities to
                meet both ambient and temperature-controlled or cold chain
                requirements.
                <br />
                At {companyname}, our integrated solutions cater to the specific
                needs of the pharmaceutical and healthcare supply chains. Our
                comprehensive offerings include warehousing and distribution,
                complemented by air, surface, coastal, and multi-modal
                transportation services, ensuring seamless logistical support.
                <br />
                Our solutions for the pharmaceutical and healthcare sector are
                developed by industry experts who possess a deep understanding
                of the industry's critical nature. We recognize the importance
                of precision, compliance, and timeliness in managing
                pharmaceutical products throughout the supply chain.
                <br />
                {companyname} places a strong emphasis on operational excellence,
                quality management, and safety. We strive to uphold the highest
                standards in our operations, ensuring the reliable and secure
                handling of pharmaceutical and healthcare products.
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
