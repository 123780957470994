import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Industries() {
  return (
    <>
      <Header />

      {/* Page Header Start */}
      <div className="container-fluid page-header py-5">
        <div className="container text-center py-5">
          <h1 className="display-2 text-white mb-4 animated slideInDown">
            About Us
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item">
                <Link to="/Home">Home</Link>
              </li>
              <li className="breadcrumb-item" aria-current="page">
              Industries
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* Page Header End */}
 {/* Project Start */}
 <div className="container-fluid project py-5 mb-5">
        <div className="container">
          <div
            className="text-center mx-auto pb-5 wow fadeIn"
            data-wow-delay=".3s"
            style={{ maxWidth: "600px" }}
          >
            <h5 className="text-primary">Our Industries</h5>
            <h1>Industry solutions we provide</h1>
          </div>
          <div className="row g-5">
            <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".3s">
              <div className="project-item">
                <div className="project-img">
                  <img
                    src="img/automotive.jpg"
                    className="img-fluid w-100 rounded"
                    alt="" style={{height:'300px'}}
                  />
                  <div className="project-content">
                    <Link to="/Automotive" className="text-center">
                      <h4 className="text-secondary">Automotive</h4>
                      <p className="m-0 text-white">Industries</p>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".3s">
              <div className="project-item">
                <div className="project-img">
                  <img
                    src="img/pharma.jpg"
                    className="img-fluid w-100 rounded"
                    alt="" style={{height:'300px'}}
                  />
                  <div className="project-content">
                    <Link to="/Pharma" className="text-center">
                      <h4 className="text-secondary">Pharma</h4>
                      <p className="m-0 text-white">Industries</p>
                    </Link>
                  </div>
                </div>
              </div>
            </div><div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".3s">
              <div className="project-item">
                <div className="project-img">
                  <img
                    src="img/metals.jpg"
                    className="img-fluid w-100 rounded"
                    alt="" style={{height:'300px'}}
                  />
                  <div className="project-content">
                    <Link to="/Metals" className="text-center">
                      <h4 className="text-secondary">Metals & Mining</h4>
                      <p className="m-0 text-white">Industries</p>
                    </Link>
                  </div>
                </div>
              </div>
            </div><div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".3s">
              <div className="project-item">
                <div className="project-img">
                  <img
                    src="img/chemicals.jpg"
                    className="img-fluid w-100 rounded"
                    alt="" style={{height:'300px'}}
                  />
                  <div className="project-content">
                    <Link to="/Chemicals" className="text-center">
                      <h4 className="text-secondary">Chemicals, Oil & Gas</h4>
                      <p className="m-0 text-white">Industries</p>
                    </Link>
                  </div>
                </div>
              </div>
            </div><div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".3s">
              <div className="project-item">
                <div className="project-img">
                  <img
                    src="img/fmcg.webp"
                    className="img-fluid w-100 rounded"
                    alt="" style={{height:'300px'}}
                  />
                  <div className="project-content">
                    <Link to="/FMCG" className="text-center">
                      <h4 className="text-secondary">FMCG</h4>
                      <p className="m-0 text-white">Industries</p>
                    </Link>
                  </div>
                </div>
              </div>
            </div><div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".3s">
              <div className="project-item">
                <div className="project-img">
                  <img
                    src="img/consumer.jpg"
                    className="img-fluid w-100 rounded"
                    alt="" style={{height:'300px'}}
                  />
                  <div className="project-content">
                    <Link to="/Consumer" className="text-center">
                      <h4 className="text-secondary">Consumer Durables</h4>
                      <p className="m-0 text-white">Industries</p>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </div>
      {/* Project End */}

      <Footer />
    </>
  );
}
