import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Metals() {
  return (
    <>
      <Header />

      {/* Page Header Start */}
      <div className="container-fluid page-header py-5">
        <div className="container text-center py-5">
          <h1 className="display-2 text-white mb-4 animated slideInDown">
            Metals and Mining
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item">
                <Link to="/Home">Home</Link>
              </li>
              <li className="breadcrumb-item" aria-current="page">
                Metals and Mining
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* Page Header End */}
      <div className="container-fluid py-5 my-5">
        <div className="container pt-5">
          <div className="row g-5">
            <div
              className="col-lg-5 col-md-6 col-sm-12 wow fadeIn"
              data-wow-delay=".3s"
            >
              <img
                src="img/metals.jpg"
                className="img-fluid  rounded mt-4"
                alt=""
                style={{ marginBottom: "25%" }}
              />
            </div>
            <div
              className="col-lg-7 col-md-6 col-sm-12 wow fadeIn"
              data-wow-delay=".5s"
            >
              <h5 className="text-primary">Metals and Mining</h5>
              <p>
                The metals and mining industry presents a range of unique
                challenges, making specialized logistics services crucial for
                avoiding delays and crises. <br />
                At {companyname}, we offer comprehensive logistics solutions that
                encompass every aspect of the supply chain, including port
                handling, road and rail transport, warehousing, and shipping.
                Our integrated approach ensures a seamless logistics experience
                tailored to meet the specific needs of each client. To enhance
                cargo handling efficiency, we have made significant investments
                in state-of-the-art infrastructure, specialized equipment, and
                handling facilities.
                <br />
                As a trusted third-party logistics provider for the metals and
                mining industry, we adhere to rigorous standards of safety,
                reliability, and capacity. Regardless of the size, dimensions,
                or type of your freight, we have the expertise and resources to
                ensure its secure and efficient movement.
                <br />
                With {companyname}, you can rely on our proven track record and
                commitment to excellence in providing logistics solutions for
                the metals and mining sector. We prioritize the safety and
                timely delivery of your freight, helping you navigate the
                challenges of the industry with confidence.
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
