import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Home() {
  return (
    <>
      <Header />

      {/* Carousel Start */}
      <section className=" w3-banner jarallax">
        <video className="img-fluid" autoPlay muted loop style={{ zIndex: -1 }}>
          <source src="img/video.mp4" type="video/mp4" />
        </video>
        <div className="container-fluid px-0 ">
          <div
            id="carouselId"
            className="carousel slide"
            data-bs-ride="carousel"
          >
            <ol className="carousel-indicators">
              <li
                data-bs-target="#carouselId"
                data-bs-slide-to={0}
                className="active"
                aria-current="true"
                aria-label="First slide"
              />
              <li
                data-bs-target="#carouselId"
                data-bs-slide-to={1}
                aria-label="Second slide"
              />
            </ol>
            <div className="carousel-inner  " role="listbox">
              <div className="carousel-item active ">
                <div style={{ height: "500px" }}>
                  <div className="carousel-caption">
                    <div className="container carousel-content">
                      <h6 className="text-secondary h4 animated fadeInUp">
                        Welcome to {companyname} 
                      </h6>
                      <h1 className="text-white mb-4 animated fadeInRight">
                      Your cargo, our priority: Reliable freight forwarding solutions.
                      </h1>
                      <p className="mb-4 text-white fs-5 animated fadeInDown">
                        We  understands the significance of customers' cargo and places it at the forefront of their operations. By offering reliable freight forwarding solutions
                      </p>
                      <Link to="/About" className="me-2">
                        <button
                          type="button"
                          className="px-4 py-sm-3 px-sm-5 btn btn-primary rounded-pill carousel-content-btn1 animated fadeInLeft"
                        >
                          Read More
                        </button>
                      </Link>
                      <Link to="/Contact" className="ms-2">
                        <button
                          type="button"
                          className="px-4 py-sm-3 px-sm-5 btn btn-primary rounded-pill carousel-content-btn2 animated fadeInRight"
                        >
                          Contact Us
                        </button>
                        </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="carousel-item">
                <div style={{ height: "500px" }}>
                  <div className="carousel-caption">
                    <div className="container carousel-content">
                      <h6 className="text-secondary h4 animated fadeInUp">
                        Best in Industry
                      </h6>
                      <h1 className="text-white  mb-4 animated fadeInLeft">
                      Simplifying logistics, expanding possibilities.
                      </h1>
                      <p className="mb-4 text-white fs-5 animated fadeInDown">
                       We aims to create a seamless experience that enables their customers to focus on their core business. 
                      </p>
                      <Link to="/About" className="me-2">
                        <button
                          type="button"
                          className="px-4 py-sm-3 px-sm-5 btn btn-primary rounded-pill carousel-content-btn1 animated fadeInLeft"
                        >
                          Read More
                        </button>
                      </Link>
                      <Link to="/Contact" className="ms-2">
                        <button
                          type="button"
                          className="px-4 py-sm-3 px-sm-5 btn btn-primary rounded-pill carousel-content-btn2 animated fadeInRight"
                        >
                          Contact Us
                        </button>
                        </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target="#carouselId"
              data-bs-slide="prev"
            >
              <span className="carousel-control-prev-icon" aria-hidden="true" />
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#carouselId"
              data-bs-slide="next"
            >
              <span className="carousel-control-next-icon" aria-hidden="true" />
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </div>
      </section>
      {/* Carousel End */}

      {/* About Start */}
      <div className="container-fluid py-5 my-5">
        <div className="container pt-5">
          <div className="row g-5">
            <div
              className="col-lg-5 col-md-6 col-sm-12 wow fadeIn"
              data-wow-delay=".3s"
            >
              <div className="h-100 position-relative">
                <img
                  src="img/about-1.jpg"
                  className="img-fluid w-75 rounded"
                  alt=""
                  style={{ marginBottom: "25%" }}
                />
                <div
                  className="position-absolute w-75"
                  style={{ top: "25%", left: "25%" }}
                >
                  <img
                    src="img/about-2.jpg"
                    className="img-fluid w-100 rounded"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div
              className="col-lg-7 col-md-6 col-sm-12 wow fadeIn"
              data-wow-delay=".5s"
            >
              <h5 className="text-primary">About Us</h5>
              <h1 className="mb-4">About {companyname}</h1>
              <p>
                {companyname} has rapidly expanded its global network from
                humble beginnings. 
              
                Our unwavering vision centers around delivering exceptional
                quality in logistics services. At {companyname}, we achieve the
                pinnacle of customer satisfaction by combining human expertise
                with cutting-edge logistics technology.
                <br />
                Over time, our expertise has flourished, enabling us to
                undertake a wide range of projects. Our services encompass Air
                Freight, Ocean Freight, Surface Transportation, Door To Door
                Express Service, Customs Brokerage as well as
                Industrial Solutions.
                
              </p>
              <p className="mb-4">
              At {companyname}, our goal is to maximize the return on
                our customers' investments in terms of time, trust, and costs.
                We excel in deeply understanding our customers' requirements and
                crafting logistics and freight solutions that provide businesses
                with a competitive edge. Regardless of your supply chain
                challenges, {companyname} possesses the capability to deliver value
                for businesses of all scales.
              </p>
              <Link
                to="/About"
                className="btn btn-secondary rounded-pill px-5 py-3 text-white"
              >
                Learn More
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* About End */}

     
      {/* Blog Start */}
      <div className="container-fluid blog py-5 mb-5">
        <div className="container">
          <div
            className="text-center mx-auto pb-5 wow fadeIn"
            data-wow-delay=".3s"
            style={{ maxWidth: "600px" }}
          >
            <h5 className="text-primary">Our Services</h5>
            <h1>Our Freight & Logistics Services</h1>
          </div>
          <div className="row g-5 justify-content-center">
           
            <div className="col-lg-6 col-xl-4 wow fadeIn" data-wow-delay=".3s">
              <div className="blog-item position-relative bg-light rounded">
                <img
                  src="img/sea.jpg"
                  className="img-fluid w-100 rounded-top"
                  alt="" style={{height:'280px'}}
                />

                <div
                  className="blog-btn d-flex justify-content-center text-center position-relative px-3"
                  style={{ marginTop: "-75px" }}
                >
                  <div className="blog-icon btn btn-secondary px-3 rounded-pill my-auto">
                    <Link to="/Sea" className="btn text-white">
                      Read More
                    </Link>
                  </div>
                </div>
                <div
                  className="blog-content text-center position-relative px-3"
                  style={{ marginTop: "30px" }}
                >
                  <h5 className>Sea Freight</h5>
                  
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-xl-4 wow fadeIn" data-wow-delay=".3s">
              <div className="blog-item position-relative bg-light rounded">
                <img
                  src="img/air.jpg"
                  className="img-fluid w-100 rounded-top"
                  alt="" style={{height:'280px'}}
                />

                <div
                  className="blog-btn d-flex justify-content-center text-center position-relative px-3"
                  style={{ marginTop: "-75px" }}
                >
                  <div className="blog-icon btn btn-secondary px-3 rounded-pill my-auto">
                    <Link to="/Air" className="btn text-white">
                      Read More
                    </Link>
                  </div>
                </div>
                <div
                  className="blog-content text-center position-relative px-3"
                  style={{ marginTop: "30px" }}
                >
                  <h5 className>Air Freight</h5>
                  
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-xl-4 wow fadeIn" data-wow-delay=".3s">
              <div className="blog-item position-relative bg-light rounded">
                <img
                  src="img/rail.jpg"
                  className="img-fluid w-100 rounded-top"
                  alt="" style={{height:'280px'}}
                />

                <div
                  className="blog-btn d-flex justify-content-center text-center position-relative px-3"
                  style={{ marginTop: "-75px" }}
                >
                  <div className="blog-icon btn btn-secondary px-3 rounded-pill my-auto">
                    <Link to="/Rail" className="btn text-white">
                      Read More
                    </Link>
                  </div>
                </div>
                <div
                  className="blog-content text-center position-relative px-3"
                  style={{ marginTop: "30px" }}
                >
                  <h5 className>Rail Freight</h5>
                  
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-xl-4 wow fadeIn" data-wow-delay=".3s">
              <div className="blog-item position-relative bg-light rounded">
                <img
                  src="img/road.png"
                  className="img-fluid w-100 rounded-top"
                  alt="" style={{height:'280px'}}
                />

                <div
                  className="blog-btn d-flex justify-content-center text-center position-relative px-3"
                  style={{ marginTop: "-75px" }}
                >
                  <div className="blog-icon btn btn-secondary px-3 rounded-pill my-auto">
                    <Link to="/Ground" className="btn text-white">
                      Read More
                    </Link>
                  </div>
                </div>
                <div
                  className="blog-content text-center position-relative px-3"
                  style={{ marginTop: "30px" }}
                >
                  <h5 className>Ground Transportation</h5>
                  
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-xl-4 wow fadeIn" data-wow-delay=".3s">
              <div className="blog-item position-relative bg-light rounded">
                <img
                  src="img/warehousing.png"
                  className="img-fluid w-100 rounded-top"
                  alt="" style={{height:'280px'}}
                />

                <div
                  className="blog-btn d-flex justify-content-center text-center position-relative px-3"
                  style={{ marginTop: "-75px" }}
                >
                  <div className="blog-icon btn btn-secondary px-3 rounded-pill my-auto">
                    <Link to="/Warehousing" className="btn text-white">
                      Read More
                    </Link>
                  </div>
                </div>
                <div
                  className="blog-content text-center position-relative px-3"
                  style={{ marginTop: "30px" }}
                >
                  <h5 className>Warehousing</h5>
                  
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-xl-4 wow fadeIn" data-wow-delay=".3s">
              <div className="blog-item position-relative bg-light rounded">
                <img
                  src="img/door.jpg"
                  className="img-fluid w-100 rounded-top"
                  alt="" style={{height:'280px'}}
                />

                <div
                  className="blog-btn d-flex justify-content-center text-center position-relative px-3"
                  style={{ marginTop: "-75px" }}
                >
                  <div className="blog-icon btn btn-secondary px-3 rounded-pill my-auto">
                    <Link to="/Door" className="btn text-white">
                      Read More
                    </Link>
                  </div>
                </div>
                <div
                  className="blog-content text-center position-relative px-3"
                  style={{ marginTop: "30px" }}
                >
                  <h5 className>Door to Door</h5>
                  
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-xl-4 wow fadeIn" data-wow-delay=".3s">
              <div className="blog-item position-relative bg-light rounded">
                <img
                  src="img/project.png"
                  className="img-fluid w-100 rounded-top"
                  alt="" style={{height:'280px'}}
                />

                <div
                  className="blog-btn d-flex justify-content-center text-center position-relative px-3"
                  style={{ marginTop: "-75px" }}
                >
                  <div className="blog-icon btn btn-secondary px-3 rounded-pill my-auto">
                    <Link to="/Project" className="btn text-white">
                      Read More
                    </Link>
                  </div>
                </div>
                <div
                  className="blog-content text-center position-relative px-3"
                  style={{ marginTop: "30px" }}
                >
                  <h5 className>Project Cargo Logistics</h5>
                  
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-xl-4 wow fadeIn" data-wow-delay=".3s">
              <div className="blog-item position-relative bg-light rounded">
                <img
                  src="img/customs.png"
                  className="img-fluid w-100 rounded-top"
                  alt="" style={{height:'280px'}}
                />

                <div
                  className="blog-btn d-flex justify-content-center text-center position-relative px-3"
                  style={{ marginTop: "-75px" }}
                >
                  <div className="blog-icon btn btn-secondary px-3 rounded-pill my-auto">
                    <Link to="/Customs" className="btn text-white">
                      Read More
                    </Link>
                  </div>
                </div>
                <div
                  className="blog-content text-center position-relative px-3"
                  style={{ marginTop: "30px" }}
                >
                  <h5 className>Customs Clearance & Documentation</h5>
                  
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-xl-4 wow fadeIn" data-wow-delay=".3s">
              <div className="blog-item position-relative bg-light rounded">
                <img
                  src="img/supply.jpg"
                  className="img-fluid w-100 rounded-top"
                  alt="" style={{height:'280px'}}
                />

                <div
                  className="blog-btn d-flex justify-content-center text-center position-relative px-3"
                  style={{ marginTop: "-75px" }}
                >
                  <div className="blog-icon btn btn-secondary px-3 rounded-pill my-auto">
                    <Link to="/Supply" className="btn text-white">
                      Read More
                    </Link>
                  </div>
                </div>
                <div
                  className="blog-content text-center position-relative px-3"
                  style={{ marginTop: "30px" }}
                >
                  <h5 className>Supply Chain Management</h5>
                  
                </div>
              </div>
            </div>
           
          </div>
        </div>
      </div>
      {/* Blog End */}
            {/* Services Start */}
            <div className="container-fluid services py-5 mb-5">
        <div className="container">
          <div
            className="text-center mx-auto pb-5 wow fadeIn"
            data-wow-delay=".3s"
            style={{ maxWidth: "600px" }}
          >
            <h5 className="text-primary">Why Choose Us</h5>
          </div>
          <div className="row g-5 services-inner">
            <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay=".3s">
              <div className="services-item bg-light">
                <div className="p-4 text-center services-content">
                  <div className="services-content-icon">
                    <i className="fa fa-truck fa-4x mb-4 text-primary" />
                    <h4 className="mb-3">CARGO DELIVERY</h4>
                    <p className="mb-4">
                      This service provides benefits to businesses of all sizes,
                      catering to a wide range of clients, from individual
                      traders to large manufacturers and importers.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay=".3s">
              <div className="services-item bg-light">
                <div className="p-4 text-center services-content">
                  <div className="services-content-icon">
                    <i className="fa fa-box fa-4x mb-4 text-primary" />
                    <h4 className="mb-3">CONTAINER HANDLING</h4>
                    <p className="mb-4">
                      We offer comprehensive services that encompass the
                      consolidation of diverse types and volumes of cargo.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay=".3s">
              <div className="services-item bg-light">
                <div className="p-4 text-center services-content">
                  <div className="services-content-icon">
                    <i className="fa fa-user fa-4x mb-4 text-primary" />
                    <h4 className="mb-3">SATISFIED CLIENTS</h4>
                    <p className="mb-4">
                      At our company, we firmly believe that customer
                      satisfaction is the cornerstone of our business.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay=".3s">
              <div className="services-item bg-light">
                <div className="p-4 text-center services-content">
                  <div className="services-content-icon">
                    <i className="fa fa-globe fa-4x mb-4 text-primary" />
                    <h4 className="mb-3">GLOBAL NETWORK</h4>
                    <p className="mb-4">
                      With our extensive network of global agents, we are
                      equipped to handle all the complexities associated with
                      shipping, logistics, and warehousing.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Services End */}
       {/* Project Start */}
       <div className="container-fluid project py-5 mb-5">
        <div className="container">
          <div
            className="text-center mx-auto pb-5 wow fadeIn"
            data-wow-delay=".3s"
            style={{ maxWidth: "600px" }}
          >
            <h5 className="text-primary">Our Industries</h5>
            <h1>Industry solutions we provide</h1>
          </div>
          <div className="row g-5">
            <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".3s">
              <div className="project-item">
                <div className="project-img">
                  <img
                    src="img/automotive.jpg"
                    className="img-fluid w-100 rounded"
                    alt="" style={{height:'300px'}}
                  />
                  <div className="project-content">
                    <Link to="/Automotive" className="text-center">
                      <h4 className="text-secondary">Automotive</h4>
                      <p className="m-0 text-white">Industries</p>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".3s">
              <div className="project-item">
                <div className="project-img">
                  <img
                    src="img/pharma.jpg"
                    className="img-fluid w-100 rounded"
                    alt="" style={{height:'300px'}}
                  />
                  <div className="project-content">
                    <Link to="/Pharma" className="text-center">
                      <h4 className="text-secondary">Pharma</h4>
                      <p className="m-0 text-white">Industries</p>
                    </Link>
                  </div>
                </div>
              </div>
            </div><div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".3s">
              <div className="project-item">
                <div className="project-img">
                  <img
                    src="img/metals.jpg"
                    className="img-fluid w-100 rounded"
                    alt="" style={{height:'300px'}}
                  />
                  <div className="project-content">
                    <Link to="/Metals" className="text-center">
                      <h4 className="text-secondary">Metals & Mining</h4>
                      <p className="m-0 text-white">Industries</p>
                    </Link>
                  </div>
                </div>
              </div>
            </div><div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".3s">
              <div className="project-item">
                <div className="project-img">
                  <img
                    src="img/chemicals.jpg"
                    className="img-fluid w-100 rounded"
                    alt="" style={{height:'300px'}}
                  />
                  <div className="project-content">
                    <Link to="/Chemicals" className="text-center">
                      <h4 className="text-secondary">Chemicals, Oil & Gas</h4>
                      <p className="m-0 text-white">Industries</p>
                    </Link>
                  </div>
                </div>
              </div>
            </div><div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".3s">
              <div className="project-item">
                <div className="project-img">
                  <img
                    src="img/fmcg.webp"
                    className="img-fluid w-100 rounded"
                    alt="" style={{height:'300px'}}
                  />
                  <div className="project-content">
                    <Link to="/FMCG" className="text-center">
                      <h4 className="text-secondary">FMCG</h4>
                      <p className="m-0 text-white">Industries</p>
                    </Link>
                  </div>
                </div>
              </div>
            </div><div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".3s">
              <div className="project-item">
                <div className="project-img">
                  <img
                    src="img/consumer.jpg"
                    className="img-fluid w-100 rounded"
                    alt="" style={{height:'300px'}}
                  />
                  <div className="project-content">
                    <Link to="/Consumer" className="text-center">
                      <h4 className="text-secondary">Consumer Durables</h4>
                      <p className="m-0 text-white">Industries</p>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </div>
      {/* Project End */}



      <Footer />
    </>
  );
}
