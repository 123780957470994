import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Customs() {
  return (
    <>
      <Header />

      {/* Page Header Start */}
      <div className="container-fluid page-header py-5">
        <div className="container text-center py-5">
          <h1 className="display-2 text-white mb-4 animated slideInDown">
          Customs Clearance
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item">
                <Link to="/Home">Home</Link>
              </li>
              <li className="breadcrumb-item" aria-current="page">
              Customs Clearance
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* Page Header End */}
      <div className="container-fluid py-5 my-5">
        <div className="container pt-5">
          <div className="row g-5">
            <div className="text-center">
              <h2 className="text-primary"> Customs Clearance</h2>{" "}
            </div>
            <div
              className="col-lg-12 col-md-6 col-sm-12 wow fadeIn"
              data-wow-delay=".3s"
            >
              <img
                src="img/customs.png"
                className="img-fluid  rounded mt-4"
                alt=""
                style={{
                  marginBottom: "1%",
                  width: "100%",
                  padding: "0px 120px",
                }}
              />
            </div>
            <div
              className="col-lg-12 col-md-6 col-sm-12 wow fadeIn"
              data-wow-delay=".5s"
            >
              <p>
              The process of customs clearance for goods involves several steps, beginning with the filing of a shipping bill and the subsequent activities associated with it. However, as an exporter, there are certain prerequisites that need to be met before seeking customs clearance for your goods. These prerequisites ensure compliance with regulations and facilitate a smooth customs clearance process. Some of the primary prerequisites include: <br/>

1. Import Export Code (IEC): An Import Export Code is a unique identification number issued by the Directorate General of Foreign Trade. It is mandatory for all businesses engaged in import and export activities in India.<br/>

2. Authorized Foreign Exchange Dealer Code: To facilitate international transactions and comply with foreign exchange regulations, exporters need to obtain an Authorized Foreign Exchange Dealer Code. This code enables smooth processing of foreign exchange transactions related to export activities.<br/>

3. Current Account for Credit of Duty Drawback: Duty drawback is a refund of duties paid on imported goods that are subsequently re-exported. To receive duty drawback benefits, exporters need to have a current account designated for the credit of duty drawback.<br/>

4. License for Export under Export Promotion Scheme: Certain export promotion schemes, such as the Merchandise Exports from India Scheme (MEIS) or the Export Promotion Capital Goods (EPCG) scheme, require exporters to obtain licenses specific to those schemes. These licenses grant eligibility for incentives and benefits under the respective export promotion schemes.<br/>

By ensuring compliance with these prerequisites, exporters can streamline the customs clearance process and fulfill the necessary requirements for exporting goods. It is important to consult with relevant authorities and seek professional guidance to ensure adherence to all regulatory requirements for customs clearance.
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
