import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Project() {
  return (
    <>
      <Header />

      {/* Page Header Start */}
      <div className="container-fluid page-header py-5">
        <div className="container text-center py-5">
          <h1 className="display-2 text-white mb-4 animated slideInDown">
          Project Cargo Logistics
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item">
                <Link to="/Home">Home</Link>
              </li>
              <li className="breadcrumb-item" aria-current="page">
                Project Cargo Logistics
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* Page Header End */}
      <div className="container-fluid py-5 my-5">
        <div className="container pt-5">
          <div className="row g-5">
            <div className="text-center">
              <h2 className="text-primary"> Project Cargo Logistics</h2>{" "}
            </div>
            <div
              className="col-lg-12 col-md-6 col-sm-12 wow fadeIn"
              data-wow-delay=".3s"
            >
              <img
                src="img/project.png"
                className="img-fluid  rounded mt-4"
                alt=""
                style={{
                  marginBottom: "1%",
                  width: "100%",
                  padding: "0px 120px",
                }}
              />
            </div>
            <div
              className="col-lg-12 col-md-6 col-sm-12 wow fadeIn"
              data-wow-delay=".5s"
            >
              <p>
             With our strong expertise in Project Cargo Logistics, we have established a solid reputation by fostering relationships with renowned operators. This enables us to offer our customers the best cargo-based solutions available in the industry. Our experienced local teams possess the capability to handle any type of cargo, regardless of its size, weight, or configuration.

             <br/> We have earned the trust of major Engineering, Procurement, and Construction (EPC) suppliers by efficiently managing their project cargo, even in the most remote locations worldwide.

             <br/> Our services include:

             <br/> - Site inspection and project planning: We conduct thorough site inspections and develop detailed project plans to ensure smooth logistics operations.
             <br/> - Route surveys and selection of reliable carriers: We perform route surveys to determine the most suitable transportation routes and carefully select reliable carriers for your project cargo.
             <br/> - Track and trace with real-time reporting: We offer real-time tracking and reporting capabilities, providing you with constant visibility into the status of your cargo.
             <br/> - Customs clearance: Our dedicated team handles all customs clearance procedures, ensuring compliance with regulations and minimizing any potential delays.
             <br/> - Final stage delivery with a closing report: We ensure the successful final delivery of your project cargo and provide a comprehensive closing report upon completion.

             <br/> Our Unique Benefits:

             <br/> - End-to-end shipment execution responsibility: We take full responsibility for the execution of your shipment from start to finish, providing you with peace of mind and convenience.
             <br/> - Global Reach with local support: With a global presence and a strong network, we offer our services across various locations while providing dedicated local support.
             <br/> - Experienced team ready to handle any cargo: Our experienced team is equipped to handle diverse types of cargo, no matter the complexity or challenges involved.
             <br/> - Solution proposals combining multiple modes from end-to-end: We offer customized solution proposals that leverage multiple modes of transportation to ensure an efficient end-to-end logistics process.
             <br/> - Complete visibility through our Frescon App: Our proprietary Frescon App provides you with complete visibility and transparency throughout the entire logistics journey.

             <br/> With our expertise, global reach, and commitment to excellence, we strive to deliver exceptional project cargo logistics services that meet and exceed your expectations.
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
