import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function whoweare() {
  return (
    <>
      <Header />

      {/* Page Header Start */}
      <div className="container-fluid page-header py-5">
        <div className="container text-center py-5">
          <h1 className="display-2 text-white mb-4 animated slideInDown">
            About Us
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item">
                <Link to="/Home">Home</Link>
              </li>
              <li className="breadcrumb-item" aria-current="page">
                About Us
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* Page Header End */}
      <div className="container-fluid py-5 my-5">
        <div className="container pt-5">
          <div className="row g-5">
            <div
              className="col-lg-5 col-md-6 col-sm-12 wow fadeIn"
              data-wow-delay=".3s"
            >
              <div className="h-100 position-relative">
                <img
                  src="img/about-1.jpg"
                  className="img-fluid w-75 rounded"
                  alt=""
                  style={{ marginBottom: "25%" }}
                />
                <div
                  className="position-absolute w-75"
                  style={{ top: "25%", left: "25%" }}
                >
                  <img
                    src="img/about-2.jpg"
                    className="img-fluid w-100 rounded"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div
              className="col-lg-7 col-md-6 col-sm-12 wow fadeIn"
              data-wow-delay=".5s"
            >
              <h5 className="text-primary">About Us</h5>
              <h1 className="mb-4">About {companyname}</h1>
              <p>
                {companyname} has rapidly expanded its global network from
                humble beginnings. 
              
                Our unwavering vision centers around delivering exceptional
                quality in logistics services. At {companyname}, we achieve the
                pinnacle of customer satisfaction by combining human expertise
                with cutting-edge logistics technology.
                <br />
                Over time, our expertise has flourished, enabling us to
                undertake a wide range of projects. Our services encompass Air
                Freight, Ocean Freight, Surface Transportation, Door To Door
                Express Service, Customs Brokerage as well as
                Industrial Solutions.
                
              </p>
              <p className="mb-4">
              At {companyname}, our goal is to maximize the return on
                our customers' investments in terms of time, trust, and costs.
                We excel in deeply understanding our customers' requirements and
                crafting logistics and freight solutions that provide businesses
                with a competitive edge. Regardless of your supply chain
                challenges, {companyname} possesses the capability to deliver value
                for businesses of all scales.
              </p>
              
            </div>
          </div>
        </div>
      </div>
      {/* Services Start */}
      <div className="container-fluid services py-5 mb-5">
        <div className="container">
          <div
            className="text-center mx-auto pb-5 wow fadeIn"
            data-wow-delay=".3s"
            style={{ maxWidth: "600px" }}
          >
            <h5 className="text-primary">Why Choose Us</h5>
          </div>
          <div className="row g-5 services-inner">
            <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay=".3s">
              <div className="services-item bg-light">
                <div className="p-4 text-center services-content">
                  <div className="services-content-icon">
                    <i className="fa fa-truck fa-4x mb-4 text-primary" />
                    <h4 className="mb-3">CARGO DELIVERY</h4>
                    <p className="mb-4">
                      This service provides benefits to businesses of all sizes,
                      catering to a wide range of clients, from individual
                      traders to large manufacturers and importers.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay=".3s">
              <div className="services-item bg-light">
                <div className="p-4 text-center services-content">
                  <div className="services-content-icon">
                    <i className="fa fa-box fa-4x mb-4 text-primary" />
                    <h4 className="mb-3">CONTAINER HANDLING</h4>
                    <p className="mb-4">
                      We offer comprehensive services that encompass the
                      consolidation of diverse types and volumes of cargo.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay=".3s">
              <div className="services-item bg-light">
                <div className="p-4 text-center services-content">
                  <div className="services-content-icon">
                    <i className="fa fa-user fa-4x mb-4 text-primary" />
                    <h4 className="mb-3">SATISFIED CLIENTS</h4>
                    <p className="mb-4">
                      At our company, we firmly believe that customer
                      satisfaction is the cornerstone of our business.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay=".3s">
              <div className="services-item bg-light">
                <div className="p-4 text-center services-content">
                  <div className="services-content-icon">
                    <i className="fa fa-globe fa-4x mb-4 text-primary" />
                    <h4 className="mb-3">GLOBAL NETWORK</h4>
                    <p className="mb-4">
                      With our extensive network of global agents, we are
                      equipped to handle all the complexities associated with
                      shipping, logistics, and warehousing.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Services End */}

      <Footer />
    </>
  );
}
